import isBrowser from "./is-browser";
import { parseJson } from "./json";

const localStorageKeys = {
  jwtToken: "jwtToken",
  onSignIn: "onSignIn",
  hubSpotToken: "hubSpotToken",
  previewSurveyManifest: "previewSurveyManifest",
  annotationStrokeHexColor: "annotationStrokeHexColor",
  annotationFillHexColor: "annotationFillHexColor",
  annotationSize: "annotationSize",
  confettiDismissedOnChallengeIDs: "confettiDismissedOnChallengeIDs",
  confettiDismissedOnIdeaBoardIDs: "confettiDismissedOnIdeaBoardIDs",
};

const setLocalStorage = (key: string, value: string): void => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return;
  }
  if (!isBrowser()) {
    return;
  }

  window.localStorage.setItem(localStorageKeys[key], value);
};

const getLocalStorage = (key: string): string => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return null;
  }
  if (!isBrowser()) {
    return null;
  }

  return window.localStorage.getItem(localStorageKeys[key]);
};

const removeLocalStorage = (key: string): void => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return;
  }
  if (!isBrowser()) {
    return;
  }

  window.localStorage.removeItem(localStorageKeys[key]);
};

const findLocalStorageArrayItem = (
  key: string,
  targetItem: number | string
): number | string | undefined => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return undefined;
  }

  if (!isBrowser()) {
    return undefined;
  }

  const rawStorage = window.localStorage.getItem(localStorageKeys[key]);
  const items = parseJson(rawStorage) ?? [];

  return items.find((item: number | string) => item === targetItem);
};

const addLocalStorageArrayItem = (
  key: string,
  newItem: number | string
): void => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return;
  }

  if (!isBrowser()) {
    return;
  }

  const rawStorage = window.localStorage.getItem(localStorageKeys[key]);
  const items = parseJson(rawStorage) ?? [];

  const existingItem = items.find((item: number | string) => item === newItem);
  if (existingItem) {
    return;
  }

  items.push(newItem);
  window.localStorage.setItem(localStorageKeys[key], JSON.stringify(items));
};

const removeLocalStorageArrayItem = (
  key: string,
  itemToRemove: number | string
): void => {
  if (!localStorageKeys[key]) {
    console.error(`Unknown localStorage key ${key}`);
    return null;
  }

  if (!isBrowser()) {
    return null;
  }

  const rawStorage = window.localStorage.getItem(localStorageKeys[key]);
  const items = parseJson(rawStorage) ?? [];

  const existingItem = items.find(
    (item: number | string) => item === itemToRemove
  );
  if (existingItem) {
    const newItems = items.filter((item) => item !== itemToRemove);
    window.localStorage.setItem(
      localStorageKeys[key],
      JSON.stringify(newItems)
    );
  }
};

const cleanLocalStorage = (): void => {
  for (const key in localStorageKeys) {
    removeLocalStorage(localStorageKeys[key]);
  }
};

export {
  localStorageKeys,
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
  findLocalStorageArrayItem,
  addLocalStorageArrayItem,
  removeLocalStorageArrayItem,
  cleanLocalStorage,
};
