export default {
  acceptedInvitation: "ตอบรับคำเชิญแล้ว",
  account: "บัญชี",
  userSettings: "จัดการบัญชี",
  actions: "เมนู",
  active: "มีการใช้งาน",
  activity: "กิจกรรม",
  add: "เพิ่ม",
  addAnother: "เพิ่ม",
  addContributors: "เพิ่มผู้เข้าร่วม",
  addCustomMessage: "เพิ่มข้อความที่กำหนดเอง?",
  addIdea: "เพิ่มไอเดีย",
  addIdeaOrQuestion: "เพิ่มไอเดียหรือคำถาม",
  additionalOptions: "ตัวเลือกอื่นๆ",
  addNewBoard: "เพิ่มกระดานไอเดียใหม่",
  addNewCategory: "เพิ่มหมวดหมู่ใหม่",
  addTag: "เพิ่มแท็ก",
  addTags: "เพิ่มแท็ก",
  addVisualsAndMedia: "เพิ่มรูปภาพและสื่อ",
  addWorkspaceMember: "เพิ่มสมาชิกในพื้นที่ทำงาน",
  administration: "แอดมิน",
  administrator: "แอดมิน",
  adminSettings: "แอดมิน",
  all: "ทั้งหมด",
  allCategoriesAreRemovedFromSelectedIdeas: "ทำการลบหมวดหมู่ทั้งหมดออกจากไอเดียที่กำหนดไว้แล้ว",
  allChallenges: "ทั้งหมด",
  allIdeas: "ทั้งหมด",
  allowIdeaBoardManagement: "อนุญาตให้จัดการกระดานไอเดียได้",
  allowIdeaBoardManagementExplanation: "อนุญาตให้สมาชิกในพื้นที่ทำงานที่ไม่ใช่แอดมิน สามารถสร้าง, จัดการ และลบกระดานไอเดียต่างๆ ได้",
  allTime: "ตลอดเวลา",
  anonymous: "นิรนาม",
  anyAttachments: "มีไฟล์แนบหรือไม่?",
  archive: "เก็บลงคลัง",
  archiveChallenge: "เก็บกระทู้ลงคลัง",
  archived: "เก็บแล้ว",
  archivedChallenges: "เก็บกระทู้ลงคลังแล้ว",
  archiveUser: "พักผู้ใช้งาน",
  archivingChallenge: "การเก็บกระทู้ลงคลังจะทำให้ผู้เข้าร่วมไม่สามารถโพสท์ไอเดียใหม่และไม่สามารถเข้าถึงหน้าเพจได้อีกต่อไป",
  areYouSureToDeleteChallenge: "คุณต้องการลบกระทู้นี้ใช่หรือไม่?",
  areYouSureToDeleteIdea: "คุณต้องการลบไอเดียนี้ใช่หรือไม่?",
  areYouSureToDeleteX: "คุณแน่ใจว่าต้องการลบ {{{name}}} หรือไม่?",
  areYouSureYouWantToDeleteXIdea: "คุณต้องการลบ {{count}} ไอเดียที่เลือกไว้ใช่หรือไม่?",
  areYouSureYouWantToDeleteXIdea_plural: "คุณต้องการลบทั้ง {{count}} ไอเดียที่เลือกไว้ใช่หรือไม่?",
  areYouSureYouWantToDeleteXMember: "คุณต้องการลบ {{count}} สมาชิกที่เลือกไว้ใช่หรือไม่?",
  areYouSureYouWantToDeleteXMember_plural: "คุณต้องการลบทั้ง {{count}} สมาชิกที่เลือกไว้ใช่หรือไม่?",
  askQuestion: "ถามคำถาม",
  assign: "กำหนดให้",
  assignedXToName: "จัดการ {{count}} ไอเดียให้กับ {{name}}",
  assignedXToName_plural: "จัดการ {{count}} ไอเดียให้กับ {{name}}",
  assignToCategory: "กำหนดหมวดหมู่",
  assignToNewCategory: "กำหนดไปยังหมวดหมู่ใหม่",
  associatedXTagToSelectedIdeas: "เพิ่ม {{count}} แท็ก ไปยังไอเดียที่ถูกเลือกไว้",
  associatedXTagToSelectedIdeas_plural: "เพิ่ม {{count}} แท็ก ไปยังไอเดียที่ถูกเลือกไว้",
  attachments: "ไฟล์แนบ",
  attachmentsCanHelp: "ภาพแนบตัวอย่างจะช่วยให้การระดมความคิดสร้างสรรค์ทำได้ง่ายยิ่งขึ้น",
  autoEvaluation: "ประมวลผลอัตโนมัติ",
  availableTeams: "ทีมที่มีอยู่",
  avoidCommonPassword: "หลีกเลี่ยงรหัสผ่านที่ง่ายเกินไป",
  avoidLowContrast: "เพื่อความชัดเจน หลีกเลี่ยงการใช้สีของข้อความและพื้นหลังที่คล้ายคลึงกัน",
  awards: "รางวัล",
  back: "ย้อนกลับ",
  backgroundColor: "พื้นหลัง",
  beTheFirstToVote: "กดโหวตคนแรก!",
  boardPrivateExplanation: "ผู้ได้รับเชิญเท่านั้นที่เห็นและเข้าร่วมกระดานไอเดีย",
  brand: "แบรนด์",
  cancel: "ยกเลิก",
  categories: "หมวดหมู่",
  category: "หมวดหมู่",
  categoryName: "ชื่อหมวดหมู่",
  categoryNameIsRequired: "กรุณากำหนดชื่อของหมวดหมู่",
  challenge: "กระทู้",
  challengeDetails: "รายละเอียด",
  challengeParticipatedIn: "กระทู้ที่มีส่วนร่วม",
  challengePrivateExplanation: "กระทู้จะไม่ปรากฏอยู่บนหน้าหลักของทีม แต่จะเข้าถึงและได้รับการแจ้งเตือนเฉพาะผู้ที่ได้รับเชิญเท่านั้น",
  challenges: "กระทู้",
  challengeTeamExplanation: "กระทู้จะปรากฏอยู่บนหน้าหลักของทีม แต่เฉพาะผู้ได้รับเชิญเท่านั้นจึงจะได้รับอีเมลแจ้งเตือน",
  changePassword: "เปลี่ยนรหัสผ่าน",
  changeRole: "แก้ไขบทบาท",
  changeStatus: "เปลี่ยนสถานะ",
  chooseALevelOfVisibility: "เลือกระดับความเป็นส่วนตัวของกระทู้ของคุณ ผู้ได้รับเชิญจะได้รับการแจ้งเตือนผ่านอีเมล",
  clear: "ล้าง",
  clearFilters: "ล้างตัวเลือก",
  clickToSeeMoreDetails: "คลิกดูรายละเอียดเพิ่มเติม",
  clickToSeeYourChallenges: "คลิกเพื่อดูกระทู้ของคุณ",
  close: "ปิด",
  code: "โค้ด",
  collaborationStage: "สเตจของการร่วมมือกัน",
  completedChallenges: "เสร็จสิ้น",
  completedOn: "เสร็จสิ้นไปเมื่อ",
  completedStage: "เสร็จสิ้น",
  confirm: "ยืนยัน",
  confirmDeleteChallengeComment: "แน่ใจหรือว่าคุณต้องการลบคอมเม้นท์นี้ ข้อความตอบกลับคอมเม้นท์ทั้งหมดจะถูกลบไปด้วยเช่นกัน",
  confirmDeleteIdeaComment: "แน่ใจหรือไม่ว่าคุณต้องการลบคอมเม้นท์นี้?",
  contrastIsTooLow: "ลองเพิ่มความแตกต่างระหว่างสีของวัตถุและพื้นหลังเพื่อความชัดเจนของการมองเห็น",
  contributions: "การมีส่วนร่วม",
  contributor: "ผู้มีส่วนร่วม",
  contributors: "ผู้มีส่วนร่วม",
  contributorsGroup: "กลุ่มผู้มีส่วนร่วม",
  createBoard: "สร้างกระดานไอเดีย",
  createChallenge: "สร้างกระทู้",
  createPrivateBoard: "สร้างกระดานไอเดียส่วนตัว",
  createWorkspace: "สร้างพื้นที่การทำงาน",
  createYourFirstBoard: "สร้างกระดานไอเดียส่วนตัวแรกของคุณ",
  createYourFirstBoardExplanation: "สร้างกระดานไอเดีย, เพิ่มคนในพื้นที่การทำงานของคุณที่จะร่วมมือกันและเริ่มต้นการเพิ่มไอเดีย! เก็บไว้ในใจ: โปรดจำไว้ว่าไม่มีการจำกัดจำนวนกระดานไอเดียหรือจำนวนไอเดีย",
  custom: "กำหนดเอง",
  customDomain: "โดเมนที่กำหนดเอง",
  customDomainAllowsYouTo: "โดเมนที่กำหนดเองจะทำให้คุณสามารถใช้โดเมนที่กำหนดเองได้อย่างสมบูรณ์..",
  customizeTheme: "ธีมที่กำหนดเอง",
  customMessage: "ข้อความที่กำหนดเอง",
  customSubdomain: "ซับโดเมนที่กำหนดเอง",
  customSubdomainWillBe: "ซับโดเมนที่กำหนดเองจะปรากฎอยู่ในแอดเดรสของบราวเซอร์",
  dashboard: "หน้าหลัก",
  date: "วันที่",
  day: "วัน",
  days: "วัน",
  default: "ค่าเริ่มต้น",
  defaultWorkspace: "พื้นที่ทำงานเริ่มต้น",
  delete: "ลบ",
  deleteAccount: "ลบบัญชี",
  deleteBoard: "ลบกระดานไอเดีย",
  deleteCategory: "ลบหมวดหมู่",
  deleteChallenge: "ลบกระทู้",
  deleteComment: "ลบคอมเม้นท์",
  deleteIdea: "ลบไอเดีย",
  deleteMember: "ลบสมาชิก",
  deleteUser: "ลบผู้ใช้งาน",
  deleteWorkspace: "ลบพื้นที่ทำงาน",
  deleteXCategory: "ลบหมวดหมู่ {{name}}",
  deletingYourAccountWillBePermanent: "การลบบัญชีของคุณจะเป็นการลบอย่างถาวร",
  describeChallenge: "อธิบายความเป็นมา เหตุผลและจุดมุ่งหมายของกระทู้ของคุณ",
  describeItInMoreDetail: "รายละเอียดเพิ่มเติม",
  descriptionIsRequired: "จำเป็นต้องใส่คำอธิบาย",
  design: "การออกแบบ",
  discussFurther: "พูดคุย",
  discussions: "การพูดคุย",
  documents: "เอกสาร",
  done: "เสร็จสิ้น",
  drafts: "ฉบับร่าง",
  draftStage: "ฉบับร่าง",
  dropFileHere: "ใส่ไฟล์ที่นี่",
  dropFilesHere: "ใส่ไฟล์ที่นี่",
  edit: "แก้ไข",
  editCategory: "แก้ไขหมวดหมู่",
  editChallenge: "แก้ไขกระทู้",
  editIdea: "แก้ไขไอเดีย",
  editTiming: "แก้ไขเวลา",
  email: "อีเมล",
  emailIsRequired: "จำเป็นต้องมีอีเมล",
  end: "จบ",
  endsWhenYouSave: "จบเมื่อกดบันทึก",
  engagement: "การมีส่วนร่วม",
  english: "English",
  enterChallengeDescription: "กรุณาใส่รายละเอียด",
  enterChallengeTitle: "หัวข้อ",
  enterDescription: "รายละเอียด",
  enterEmailHere: "อีเมล",
  enterMessage: "ใส่ข้อความ",
  enterNameHere: "ชื่อ",
  enterNewBoardNameHere: "ป้อนชื่อกระดานไอเดียใหม่ที่นี่",
  enterNewCategoryName: "ใส่ชื่อของหมวดหมู่ที่จะสร้างขึ้นใหม่...",
  enterNewCategoryNameHere: "ใส่ชื่อของหมวดหมู่ที่จะสร้างขึ้นใหม่",
  enterNewWorkspaceHere: "ใส่ชื่อของพื้นที่ทำงานที่จะสร้างขึ้นใหม่",
  enterTitle: "ใส่หัวข้อ",
  error: "มีข้อผิดพลาด",
  errorOccurred: "มีข้อผิดพลาดบางอย่างเกิดขึ้น",
  evaluationStage: "ประเมินผล",
  existingTagsHaveBeenRemoved: "แท็กที่มีอยู่ได้ถูกลบออกจากไอเดียที่เลือก",
  explainReward: "รายละเอียดรางวัล",
  extend: "ยืดเวลา",
  failedToVerifyMention: "มีข้อผิดพลาดในการกล่าวถึง โปรดลองอีกครั้งในภายหลัง",
  favorites: "ชื่นชอบ",
  fieldIsRequired: "จำเป็นต้องใส่ข้อมูลนี้",
  fileExceedsSizeLimit: "ไฟล์ที่อัพโหลดขึ้นมามีขนาดใหญ่กว่าที่กำหนดไว้",
  filterBy: "กรองตาม",
  filterByContributions: "การกรองจากการมีส่วนร่วม",
  filterByTags: "กรองด้วยแท็ก",
  filterByTeam: "กรองด้วยทีม",
  findAvailableMediaTypes: "ค้นหาประเภทมีเดีย",
  forgotPassword: "ลืมรหัสผ่าน?",
  fullLogo: "โลโก้แบบเต็ม",
  fullTeam: "ทั้งทีม",
  generalSettings: "พื้นฐาน",
  getLink: "สร้างลิงค์",
  givePraise: "ชื่นชม",
  googleImageSearch: "ค้นหาภาพด้วยกูเกิ้ล",
  grantWorkspaceAdmin: "มอบหมายให้เป็นแอดมิน",
  groups: "กลุ่ม",
  guest: "ผู้เข้าร่วมเฉพาะกิจ",
  help: "ช่วยเหลือ",
  hours: "ชั่วโมง",
  idea: "ไอเดีย",
  ideaBoard: "กระดานไอเดียส่วนตัว",
  ideas: "ไอเดีย",
  ideasWillBeUnassigned: "ไอเดียทั้งหมดในหมวดหมู่นี้จะยังคงอยู่ แต่จะถูกลบออกจากหมวดหมู่เท่านั้น",
  ideationStage: "สเตจของการระดมความคิด",
  ideaWasImportedFromAChallenge: "ไอเดียนี้ถูกนำเข้ามาจากกระทู้",
  ideaWasSentDirectly: "ไอเดียนี้ถูกส่งโดยตรง",
  ideaWasSentViaApp: "ไอเดียนี้ถูกส่งโดยแอพพลิเคชั่น Invent Value",
  ideaWasSentViaEmail: "ไอเดียนี้ถูกส่งโดยอีเมล",
  ideaWasSentViaSlack: "ไอเดียนี้ถูกส่งโดย Slack",
  images: "รูปภาพ",
  implemented: "การดำเนินการ",
  inactive: "ไม่มีความเคลื่อนไหว",
  insert: "เพิ่ม",
  invalidEmailAddress: "ที่อยู่อีเมลไม่ถูกต้อง",
  invitationMessage: "ข้อความสำหรับคำเชิญ",
  inviteParticipant: "เชิญผู้เข้าร่วมนี้หรือไม่?",
  inviteParticipantAsGuest: "เชิญผู้ใช้งานอีเมลนี้ เพื่อให้เข้าร่วมเฉพาะกระทู้นี้เท่านั้น?",
  invitePeople: "ส่งคำเชิญ",
  inviteX: "เชิญ {{name}}",
  japanese: "Japanese",
  leaveWorkspace: "ออกจากพื้นที่ทำงานนี้",
  letsCreateFirstWorkspace: "มาสร้างพื้นที่ทำงานแรกของคุณกัน!",
  linkCopied: "คัดลอกลิงค์",
  linkedIdeas: "ไอเดียที่ถูกเชื่อมโยง",
  linkIdea: "เชื่อมโยงไอเดีย",
  linkToIdeas: "เชื่อมโยงไปยังไอเดีย",
  linkToXIdea: "เชื่อมโยงแล้ว {{count}} ไอเดีย",
  linkToXIdea_plural: "เชื่อมโยงแล้ว {{count}} ไอเดีย",
  listView: "มุมมองแบบรายชื่อ",
  liveChallenges: "กำลังดำเนินการ",
  loading: "กำลังโหลด...",
  loadMore: "โหลดเพิ่มเติม",
  logo: "โลโก้",
  logout: "ออกจากระบบ",
  makeDefault: "กำหนดเป็นค่าเริ่มต้น",
  makeSureValidTimes: "กรุณาตรวจสอบให้แน่ใจว่าเวลาของแต่ละขั้นต่างๆนั้นถูกต้อง",
  manageBoard: "จัดการกระดานไอเดีย",
  manageParticipants: "จัดการผู้เข้าร่วม",
  maxUploadFileSize: "ขนาดของไฟล์สูงสุดที่อัพโหลดได้",
  medium: "กลาง",
  member: "สมาชิก",
  members: "สมาชิก",
  menu: "เมนู",
  menuTextColor: "ข้อความของเมนู",
  minute: "นาที",
  minutes: "นาที",
  missingName: "ชื่อขาดหายไป",
  moderators: "ผู้ดำเนินการแทน",
  month: "เดือน",
  months: "เดือน",
  mostActivities: "มีกิจกรรมมากที่สุด",
  mostActivity: "มีกิจกรรมมากที่สุด",
  mostContributions: "มีส่วนร่วมมากที่สุด",
  mostIdeasContributed: "แชร์ไอเดียมากที่สุด",
  mostPraiseReceived: "ได้รับการชื่นชมมากที่สุด",
  mostRecent: "ล่าสุด",
  mostVotes: "โหวตมากที่สุด",
  moveToNextStage: "ข้ามไปยังสเตจถัดไป",
  moveToPreviousStage: "ย้อนกลับไปยังสเตจก่อนหน้า",
  multipleContributors: "สมาชิกหลายคน",
  mustBeLessThanXCharactersLong: "ความยาวตัวอักษรต้องน้อยกว่า {{count}}",
  mustBeValidDomain: "กรุณาใส่โดเมนที่ถูกต้อง",
  name: "ชื่อ",
  nameIsRequired: "กรุณาใส่ชื่อ",
  navBar: "เมนูนำทาง",
  newChallenge: "กระทู้ใหม่",
  newIdeaBoard: "กระดานไอเดียใหม่",
  newPassword: "รหัสผ่านใหม่",
  newPasswordIsRequired: "รหัสผ่านใหม่เป็นสิ่งจำเป็นต้องกรอก",
  newSuggestion: "กระดานไอเดียสาธารณะใหม่",
  newWorkspaceMember: "สมาชิกในพื้นที่ทำงานใหม่",
  next: "ถัดไป",
  noQualifyingTopContributions: "ไม่มีผู้ที่มีส่วนร่วมมากที่สุดได้รับการคัดเลือก",
  noQualifyingTopIdeas: "ไม่มีสุดยอดไอเดียที่ได้รับการคัดเลือก",
  noSearchResults: "ไม่พบผลการค้นหา กรุณาลองใช้คำอื่นๆในการค้น",
  notApplicable: "ไม่มีข้อมูล",
  notifications: "การแจ้งเตือน",
  off: "ปิด",
  oldest: "เก่าสุด",
  oldPassword: "รหัสผ่านเก่า",
  on: "เปิด",
  onlyBoardMembersCanViewThisIdea: "สมาชิกในกระดานไอเดียเท่านั้นที่สามารถดูไอเดียนี้ได้",
  openMenu: "เปิดเมนู",
  optional: "เป็นทางเลือก",
  organizeIdeasEasily: "จัดระเบียบไอเดียแบบง่ายๆ",
  organizeIdeasEasilyExplanation: "จัดระเบียบไอเดียแบบง่ายๆ โดยการสร้างหมวดหมู่ และเพิ่มไอเดียลงหมวดหมู่ต่างๆ",
  other: "อื่นๆ",
  otherFilters: "ตัวกรองอื่นๆ",
  pageDoesNotExist: "ไม่พบหน้าที่คุณต้องการ",
  participants: "ผู้มีส่วนร่วม",
  participantWillBeInvitedAsGuest: "ผู้ใช้งานนี้ไม่ได้อยู่ในพื้นที่การทำงานนี้อีกต่อไป คุณยังสามารถส่งคำเชิญให้ผู้ใช้งานนี้ในฐานะผู้เข้าร่วมเฉพาะของกระทู้นี้",
  password: "รหัสผ่าน",
  passwordIsRequired: "กรุณาใส่รหัสผ่าน",
  passwordStrength: "ความรัดกุมของรหัสผ่าน",
  pendingInvitation: "ยังไม่ได้ตอบรับคำเชิญ",
  planned: "การวางแผน",
  pleaseEnterValidURL: "กรุณาใส่ URL ที่ถูกต้อง",
  pleaseEnterValidXURL: "กรุณาใส่ URL ของ {{name}} ที่ถูกต้อง",
  pleaseTryAgainLater: "กรุณาลองอีกครั้งภายหลัง",
  pleaseTypeDeleteToConfirm: "กรุณาพิมพ์ {{keyword}} เพื่อยืนยัน",
  pointsReceived: "คะแนนที่ได้รับ",
  post: "โพสท์",
  praise: "การชื่นชม",
  praises: "การชื่นชม",
  preferredLanguage: "เลือกภาษา",
  preview: "แสดงตัวอย่าง",
  privacy: "ความเป็นส่วนตัว",
  private: "เฉพาะคนที่กำหนด",
  process: "กระบวนการ",
  profile: "ข้อมูลส่วนตัว",
  profilePhoto: "รูปประจำตัว",
  publishChallenge: "เผยแพร่กระทู้",
  question: "คำถาม",
  questions: "คำถาม",
  quickAdd: "ทางลัดในการเพิ่มสื่อ",
  received: "ได้รับ",
  receivesNotifications: "ได้รับการแจ้งเตือน",
  recentlyCompleted: "เสร็จสิ้นล่าสุด",
  recognition: "การคัดเลือก",
  reminderHasBeenSent: "อีเมลแจ้งเตือนถูกส่งไปแล้ว",
  remove: "ลบออก",
  removedXFromYourWorkspace: "ลบ {{name}} ออกจากพื้นที่การทำงานของคุณ",
  removedXIdeaFromY: "ลบ {{count}} ไอเดียจาก {{name}}",
  removedXIdeaFromY_plural: "ลบ {{count}} ไอเดียจาก {{name}}",
  removeXFromWorkspace: "คุณแน่ใจหรือไม่ว่าต้องการลบ {{name}} ออกจากพื้นที่การทำงานของคุณ? สิ่งใดๆก็ตามที่ผู้ใช้งานนี้ได้เคยทำไว้จะยังคงอยู่เช่นเดิม",
  renameCategory: "เปลื่ยนชื่อหมวดหมู่",
  reOpen: "เริ่มต้นใหม่อีกครั้ง",
  reopeningWillEraseActivitiesFromCollaboration: "การเปิดสเตจใหม่อีกครั้งจะลบกิจกรรมทั้งหมดที่เคยทำในสเตจการร่วมมือกัน",
  reset: "ตั้งค่าใหม่",
  resetPassword: "เปลี่ยนรหัสผ่านใหม่",
  review: "รีวิว",
  reviewed: "ตรวจสอบแล้ว",
  revokeWorkspaceAdmin: "ถอดถอนตำแหน่งแอดมิน",
  reward: "รางวัล",
  role: "ตำแหน่ง / หน้าที่",
  save: "บันทึก",
  saveChanges: "บันทึกการเปลี่ยนแปลง",
  savePhoto: "บันทึกรูปภาพ",
  saving: "กำลังบันทึก ...",
  savingChanges: "กำลังบันทึก ...",
  schedule: "กำหนดการ",
  scheduledChallenges: "มีกำหนดการแล้ว",
  score: "คะแนน",
  search: "ค้นหา",
  searchChallenges: "ค้นหากระทู้",
  searchContributions: "ค้นหากิจกรรม",
  searchForImages: "ค้นหารูปภาพ",
  searchIdeas: "ค้นหาไอเดีย",
  searchMembers: "ค้นหาสมาชิก",
  second: "วินาที",
  seconds: "วินาที",
  seeAllChallenges: "ดูกระทู้ทั้งหมด",
  seeDetails: "ดูรายละเอียด",
  select: "เลือก",
  selectABoard: "เลือกกระดานไอเดีย",
  selectAllIdeas: "เลือกไอเดียทั้งหมด",
  selectAllMembers: "เลือกสมาชิกทั้งหมด",
  selectCategories: "เลือกหมวดหมู่",
  selectIdea: "เลือกไอเดียนี้",
  selectParticipants: "ผู้เข้าร่วม",
  sendInvitation: "ส่งคำเชิญ",
  sendReminder: "ส่งการแจ้งเตือน",
  sendReminderExplanation: "คุณต้องการที่จะส่งอีเมลแจ้งเตือนให้กับสมาชิกที่ยังไม่ได้มีส่วนร่วมในกระทู้นี้หรือไม่?",
  setDurationForPhrase: "ตั้งระยะเวลาสำหรับแต่ละสเตจของกระทู้",
  setOptions: "ตั้งค่าตัวเลือก",
  setPassword: "ตั้งค่ารหัสผ่าน",
  setRewardsPointsCertificates: "กำหนดรางวัลของคุณ",
  setTimeline: "ตั้งระยะเวลา",
  settings: "การตั้งค่า",
  share: "แบ่งปัน",
  shareAnonymously: "เผยแพร่ด้วยโหมดนิรนาม?",
  shared: "แชร์แล้ว",
  shareIdea: "แชร์ไอเดีย",
  shareRelatedIdea: "แชร์ไอเดียที่เกี่ยวข้อง",
  shareYourIdeaNow: "แชร์ไอเดียของคุณในตอนนี้เลย!",
  shareYourIdeaNowExplanation: "การแบ่งปันไอเดียเป็นเรื่องง่ายจริงๆ! คุณยังสามารถเพิ่มภาพและวิดีโอเพื่อให้ไอเดียของคุณเห็นภาพมากขึ้น",
  showLess: "แสดงน้อยลง",
  showMore: "แสดงมากขึ้น",
  showPassword: "แสดงรหัสผ่าน",
  signIn: "ลงชื่อเข้าใช้",
  signUp: "สมัครใช้งาน",
  slides: "สไลด์",
  somethingWentWrong: "มีบางอย่างผิดปกติ กรุณาลองอีกครั้งในภายหลัง",
  sortBy: "กรองด้วย",
  sortByNameAlphabetical: "เรียงตามชื่อตัวอักษร",
  squareLogo: "โลโกสี่เหลี่ยมจัตุรัส",
  starIdea: "ติดดาวไอเดียนี้",
  starred: "ติดดาวแล้ว",
  start: "เริ่ม",
  startsWhenCollaborateEnded: "เริ่มต้นเมื่อ “ขั้นการร่วมมือกัน” ได้จบลง",
  startsWhenIdeateEnded: "เริ่มต้นเมื่อ “ขั้นการระดมไอเดีย” ได้จบลง",
  startsWhenYouSave: "เริ่มต้นเมื่อคุณกดบันทึก",
  status: "สเตตัส",
  strong: "รัดกุม",
  subdomainIsRequired: "Subdomain เป็นสิ่งจำเป็นต้องกรอก",
  submit: "ส่ง",
  submittedByX: "นำเสนอโดย X",
  successfullyRenamedToX: "เปลี่ยนชื่อเป็น {{name}} สำเร็จ",
  successfullyUpdatedX: "อัพเดทเป็น {{title}} สำเร็จ",
  suggestions: "กระดานไอเดียสาธารณะ",
  tagSelectedIdeas: "เพิ่มแท็กไปยังไอเดียที่ถูกเลือกไว้",
  team: "ทีม",
  teamMember: "สมาชิกของทีม",
  teamMemberIdeasAreHidden: "ไอเดียของสมาชิกจะถูกซ่อนไว้จนกว่าจะถึง “ขั้นของการร่วมมือกัน” เพื่อส่งเสริมความคิดสร้างสรรค์และหลีกเลี่ยงการเกิดอิทธิพลทางความคิด",
  thai: "ไทย",
  theme: "ธีม",
  thereAreNoNewNotifications: "ไม่มีการแจ้งเตือนใหม่",
  theWorkspaceInfoIsUpdated: "ข้อมูลของพื้นที่การทำงานได้รับการอัพเดทแล้ว",
  thisMonth: "เดือน",
  thisStageHasAlreadyEnded: "สเตจนี้ได้จบลงแล้ว",
  thisWeek: "สัปดาห์",
  thisYear: "ปี",
  timeline: "ระยะเวลา",
  tip: "แนะนำ",
  titleIsRequired: "ชื่อเรื่องเป็นสิ่งจำเป็นต้องกรอก",
  titleOrDescriptionIsRequired: "ต้องการหัวข้อหรือคำอธิบายอย่างใดอย่างหนึ่ง",
  toggleFilters: "ตัวกรอง Toggle",
  toMakeYourPasswordMoreSecure: "เพื่อให้รหัสผ่านของคุณปลอดภัยมากขึ้น",
  topContributions: "สุดยอดผู้มีส่วนร่วม",
  topIdeas: "สุดยอดไอเดีย",
  topParticipation: "มีส่วนร่วมมากที่สุด",
  total: "ทั้งหมด",
  typeNameOrEmail: "ใส่ชื่อหรืออีเมล",
  unarchive: "ยกเลิกการเก็บเข้าคลัง",
  unArchiveChallenge: "ยกเลิกการเก็บกระทู้เข้าคลัง",
  unArchivingChallenge: "การยกเลิกการเก็บกระทู้เข้าคลัง จะอนุญาตให้ผู้เข้าร่วมเข้าถึงกระทู้ได้แต่ยังคงไม่สามารถส่งไอเดียเพิ่มได้เช่นเดิม",
  unassigned: "ไม่มีหมวดหมู่",
  unassignedIdeas: "ไอเดียยังไม่รับการจัดหมวดหมู่",
  unauthorizedAccess: "ไม่ได้รับอนุญาตให้เข้าถึง",
  unauthorizedToEdit: "ไม่ได้รับอนุญาตให้แก้ไข",
  underEvaluation: "อยู่ระหว่างการประมวลผล",
  unknown: "ไม่ทราบ",
  unselectIdea: "ยกเลิกการเลือกไอเดียนี้",
  unstarIdea: "ยกเลิกการติดดาวไอเดียนี้",
  upcomingStage: "กำลังเริ่ม",
  update: "อัพเดท",
  upload: "อัพโหลด",
  uploadYourLogo: "อัพโหลดโลโก้ของคุณ",
  useBothFormatsOfYourLogo: "เพื่อการรับรู้ที่ดีที่สุด ให้อัพโหลดโลโก้ทั้งสองฟอร์แม็ต ; จะช่วยให้ดูสวยงามในอุปรณ์ที่ต่างกัน เช่น แท็บเล็ตหรือมือถือ หากคุณมีโลโก้เพียงฟอร์แม็ตเดียว กรุณาอัพโหลดลงในประเภทที่จะเหมาะกับโลโก้ของคุณมากที่สุด",
  useInventValueLogo: "ใช้โลโก้ของ Invent Value",
  useInventValueTheme: "ใช้ธีมของ Invent Value",
  useUncommonWords: "ใช้คำที่ไม่ใช่คำทั่วไป",
  useUniquePhrase: "ใช้คำหรือวลีเฉพาะ",
  useYourFullName: "เพื่อการรับรู้ที่ดีกว่า ใช้ชื่อและรูปจริงของคุณ",
  verifyingCredentials: "กำลังตรวจสอบข้อมูลของคุณ",
  videos: "วิดิโอ",
  viewAllChangeLog: "ดูการเปลี่ยนแปลงทั้งหมด",
  viewAllIdeasWithThisTag: "คลิกเพื่อดูไอเดียทั้งหมดที่มีแท็กนี้",
  votes: "โหวต",
  votesLeft: "โหวตที่เหลือ",
  votesPerParticipant: "โหวตต่อ 1 สมาชิก",
  warning: "คำเตือน",
  weak: "อ่อน",
  whatsTheBigIdea: "ไอเดียสุดเจ๋งของคุณคือ?",
  whatsYourChallengeAbout: "กระทู้ของคุณเกี่ยวกับอะไร?",
  willDeleteTheWorkspace: "จะทำการลบพื้นที่ทำงาน, กระทู้, ไอเดียและข้อความทั้งหมดที่ได้มีการพูดคุยไปแล้ว",
  workspaceAdmin: "แอดมิน",
  workspaceHasBeenDeleted: "พื้นที่การทำงานได้ถูกลบออก",
  workspaceInfo: "ข้อมูลของพื้นที่ทำงาน",
  workspaceName: "ชื่อของพื้นที่ทำงาน",
  workspaceNameIsRequired: "จำเป็นต้องใส่ชื่อของพื้นที่ทำงาน",
  workspaces: "พื้นที่การทำงาน",
  workspaceTeam: "สมาชิกของพื้นที่ทำงาน",
  writeDescriptionHere: "เขียนคำอธิบาย",
  writeYourDiscussionHere: "เขียนคำอธิบายของคุณ",
  writeYourIdeaHere: "เขียนไอเดียของคุณ",
  writeYourPraiseHere: "เขียนคำชื่นชมของคุณ",
  writeYourQuestionHere: "เขียนคำถามของคุณ",
  xContribution: "{{count}} การมีส่วนร่วม",
  xContribution_plural: "{{count}} การมีส่วนร่วม",
  xDay: "{{count}} วัน",
  xDay_plural: "{{count}} วัน",
  xHasBeenAdded: "{{title}} ถูกเพิ่มสำเร็จ",
  xHasBeenCreated: "{{title}} ถูกสร้างสำเร็จ.",
  xHasBeenDeleted: "{{title}} ถูกลบแล้ว",
  xHasBeenEdited: "{{title}} ได้รับการแก้ไขเรียบร้อยแล้ว",
  xHasBeenUpdated: "{{title}} ถูกอัพเดทแล้ว",
  xHour: "{{count}} ชั่วโมง",
  xHour_plural: "{{count}} ชั่วโมง",
  xIdeaHasBeenLinkedToYOtherIdea: "{{title}} ไอเดียถูกเชื่อมเข้ากับไอเดียอีก {{count}} ไอเดีย",
  xIdeaHasBeenLinkedToYOtherIdea_plural: "{{title}} ไอเดียถูกเชื่อมเข้ากับไอเดียอื่นๆอีก {{count}} ไอเดีย",
  xIdeaHaveBeenDeleted: "{{count}} ไอเดียถูกลบแล้ว",
  xIdeaHaveBeenDeleted_plural: "{{count}} ไอเดียถูกลบแล้ว",
  xIdeaSelected: "{{count}} ไอเดียถูกเลือก",
  xIdeaSelected_plural: "{{count}} ไอเดียถูกเลือก",
  xMemberHaveBeenDeleted: "สมาชิก {{count}} คนถูกลบออกไปแล้ว",
  xMemberHaveBeenDeleted_plural: "สมาชิก {{count}} คนถูกลบออกไปแล้ว",
  xMemberSelected: "สมาชิก {{count}} คนถูกเลือก",
  xMemberSelected_plural: "สมาชิก {{count}} คนถูกเลือก",
  xMinute: "{{count}} นาที",
  xMinute_plural: "{{count}} นาที",
  xMonth: "{{count}} เดือน",
  xMonth_plural: "{{count}} เดือน",
  xNeedToBeInvitedToBoardForMention: "คุณไม่สามารถกล่าวถึงคนที่อยู่นอกกระดานไอเดียส่วนตัวได้ ดังนั้นคุณต้องการเชิญ {{name}} เข้าร่วมกระดานไอเดียนี้หรือไม่?",
  xSecond: "{{count}} วินาที",
  xSecond_plural: "{{count}} วินาที",
  xSuggestionsAssignedToStatus: "{{count}} ไอเดีย ถูกจัดการให้อยู่ในสถานะ {{status}}",
  xSuggestionsAssignedToStatus_plural: "{{count}} ไอเดีย ถูกจัดการให้อยู่ในสถานะ {{status}}",
  xUpdateStatusOfIdea: "อัพเดตสถานะของไอเดียนี้เป็น {{status}}",
  xUsersHasBeenInvitedToJoinWorkspace: "ผู้ใช้งาน {{count}} คน ถูกเชิญให้เข้าร่วมพื้นที่การทำงานของคุณ",
  xUsersHasBeenInvitedToJoinWorkspace_plural: "ผู้ใช้งานทั้งหมด {{count}} คน ถูกเชิญให้เข้าร่วมพื้นที่การทำงานของคุณ",
  xWeek: "{{count}} สัปดาห์",
  xWeek_plural: "{{count}} สัปดาห์",
  xYear: "{{count}} ปี",
  xYear_plural: "{{count}} ปี",
  year: "ปี",
  years: "ปี",
  youAreNotAuthorizedToDeleteSelectedIdeas: "คุณไม่ได้รับสิทธิ์ในการลบไอเดียที่เลือกไว้",
  youDoNotHaveAccessToThisWorkspace: "คุณไม่ได้รับสิทธิ์ในการเข้าถึงพื้นที่ทำงานนี้",
  youDoNotHavePermissionToViewThisPage: "คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้",
  yourAccountHasBeenDeleted: "บัญชีของคุณถูกลบแล้ว",
  yourPasswordIsSet: "รหัสผ่านถูกตั้งค่าแล้ว",
  yourPasswordIsUpdated: "รหัสผ่านได้รับการอัพเดทแล้ว",
  yourProfileIsUpdated: "ข้อมูลส่วนตัวของคุณถูกอัพเดทแล้ว",
  yourWorkspaceNameWillAppear: "ชื่อของพื้นที่การทำงานของคุณจะปรากฏอยู่ในพื้นที่การทำงานบนมุมขวาของหน้าจอของเว็บและในแท็บของบราวเซอร์",
  youWillLoseAccess: "คุณจะสูญเสียการเข้าถึงพื้นที่การทำงานของคุณและกิจกรรมตทั้งหมด",
  zeroActivities: "ไม่มีกิจกรรมใดๆ",
  zeroContributions: "ไม่มีการมีส่วนร่วม",
  zeroNotifications: "ไม่มีการแจ้งเตือนใหม่",
  zeroVotes: "ไม่มีโหวตใดๆ",
  voteFailed: "การโหวตล้มเหลว",
  innovationDirectory: "แฟ้มรวบรวมแนวทางให้เกิดนวตกรรมใหม่",
  assignToStatus: "สถานะงานที่มอบหมาย",
  createThisChallenge: "สร้าง Challenge นี้",
  viewChallenge: "มุมมองของ Challenge",
  privateChallenge: "Challenge ส่วนตัว",
  more: "เพิ่มมากขึ้น",
  noResultsFound: "ไม่พบผลลัพธ์ที่ต้องการค้นหา",
  reopeningWillEraseActivitiesFromEvaluation: "การเปิดระบบ Challenge ใหม่จะทำการ reset คะแนน vote ในขั้นตอนลงคะแนนเสียง",
  reopeningWillEraseActivitiesFromCompleted: "การเปิดระบบ Challenge ใหม่จะไม่สามารถยกเลิกการให้รางวัลแก่ผู้ชนะในขั้นตอนสุดท้ายก่อนหน้าได้",
  onlyChallengeMemberCanViewThis: "เฉพาะสมาชิกของ challenge นี้เท่านั้นที่สามารถมองเห็นได้",
  allowOnlyNumberMinToMax: "กรุณากรอกตัวเลข {{min}} ถึง {{max}} เท่านั้น",
  getIdeas: "ดึงเอาแนวคิดนี้มาใช้",
  duration: "ระยะเวลา",
  challengeHasStarted: "Challenge ได้เริ่มต้นขึ้นแล้ว",
  startsNow: "เริ่มตอนนี้",
  setEndTime: "ตั้งค่าเวลาสิ้นสุด",
  startsWhenIdeationEndsOnX: "เริ่มเมื่อขั้นตอนการเสนอแนวคิดสิ้นสุดเริ่ม {{time}}",
  ideationStarts: "เริ่มขั้นตอนการเสนอแนวคิด",
  ends: "สิ้นสุด",
  categoriesContributorsConnections: "ประเภท, ผู้มีส่วนร่วม และ การเชื่อมโยง",
  assignCategory: "ระบุประเภท",
  noAttachment: "ไม่มีไฟล์แนบ",
  xAttachment: "{{count}} ไฟล์แนบ",
  xAttachment_plural: "{{count}} ไฟล์แนบหลายไฟล์",
  recommendationEngine: "ข้อมูลแนะนำจากระบบ",
  articles: "บทความหลายๆ บทความ",
  visuals: "ภาพหลายๆ ภาพ",
  addAttachments: "เพิ่มไฟล์แนบ (ชิ้นที่)",
  download: "ดาว์นโหลด",
  resources: "ทรัพยากร",
  addResource: "เพิ่มทรัพยากร",
  newResource: "ทรัพยากรใหม่",
  principle: "หลักการ",
  whitepaper: "สมุดปกขาว",
  article: "หัวข้อ",
  link: "ลิงค์เชิ่อมโยง",
  video: "วีดีโอ",
  areYouSureYouWantToDeleteResource: "คุณแน่ใจใช่ไหมที่จะลบทรัพยาการนี้ออก",
  deleteResource: "ลบทรัพยากร",
  premium: "สิทธิพิเศษ",
  public: "ทำให้เป็นสาธารณะ",
  enterBriefDescription: "โปรดใส่คำอธิบายพอสังเขป",
  title: "หัวข้อ",
  briefDescription: "คำอธิบายพอสัปเขป",
  content: "เนื้อหา",
  enterVideoUrl: "โปรดใส่ที่อยู่ URL ของ video",
  enterUrl: "โปรดใส่ที่อยู่ URL",
  attachment: "ไฟล์แนบ",
  coverImage: "ภาพหน้าปก",
  firstName: "ชื่อจริง",
  lastName: "นามสกุล",
  company: "บริษัทสังกัด",
  downloading: "กำลังโหลดข้อมูล",
  copyLink: "ก๊อปปี้ลิงค์ข้อมูล",
  viewAllResources: "มองดูแหล่งทรัพยากรทั้งหมด",
  xIsRequired: "{{name}} จำเป็นต้องใส่",
  description: "คำอธิบาย",
  requestedPageNotFound: "ไม่พบข้อมูลหน้าเพจที่ส่งคำขอเข้ามา",
  evaluation: "ประเมินผลคะแนน",
  autoEvaluate: "ประเมินผลคะแนนอัตโนมัติ",
  issueCertificate: "มอบหมายใบประกาศให้",
  rewardInfo: "เสนอรางวัลให้กับแนวคิดที่ดีที่สุดเพื่อส่งเสริมการมีส่วนร่วมในกิจกรรม",
  issueCertificateInfo: "ประกาศผลผู้ชนะที่จะได้รับใบประกาศโดยผู้ชนะสามารถดาว์นโหลดไฟล์และเก็บไว้ใน desktop ส่วนตัวได้",
  autoEvaluateInfo: "Invent Value จะทำการคัดเลือกผู้ชนะจากรายงานการเข้าร่วมกิจกรรมใน Challenge นี้",
  votesReceived: "ได้รับคะแนนโหวต",
  praiseReceived: "ได้รับคำชม",
  commentsReceived: "ได้รับความเห็น",
  ideasShared: "แนวคิดได้ถูกแชร์ออกไป",
  points: "คะแนน",
  finishEvaluation: "เสร็จสิ้นการตัดสินคะแนน",
  totalPointsMustBe100: "คะแนนรวมทั้งหมดจะต้องเท่ากับ 100",
  pleaseConfirm: "กรุณายืนยัน",
  autoEvaluateWarning: "ผู้ชนะจะถูกเลือกจากระบบอัติโนมัติโดยอ้างอิงจากการโหวตและคำชมที่ได้รับและการให้ความร่วมมือในกิจกรรม Challenge นี้",
};
