export default {
  acceptedInvitation: "Accepted",
  account: "アカウント",
  userSettings: "アカウント設定",
  actions: "メニュー",
  active: "アクティブ",
  activity: "活動",
  add: "追加する",
  addAnother: "更に追加する",
  addChangelog: "新しい変更ログ",
  addContributors: "投稿者",
  addCustomMessage: "メッセージをカスタマイズする",
  addIdea: "新しいアイデア",
  addIdeaOrQuestion: "アイデア・質問を投稿",
  additionalOptions: "オプション",
  addNewCategory: "新しいカテゴリー",
  addTag: "タグ付け",
  addTags: "新しいタグ",
  addVisualsAndMedia: "メディアを挿入",
  addWorkspaceMember: "新しいメンバー",
  administration: "管理",
  administrator: "管理者",
  adminSettings: "ワークスペース管理",
  all: "全て",
  allCategoriesAreRemovedFromSelectedIdeas: "選択されたアイデアからカテゴリーが外されました",
  allChallenges: "全てのチャレンジ",
  allIdeas: "全てのアイデア",
  allTime: "全て",
  anonymous: "匿名",
  anyAttachments: "添付ファイル",
  archive: "アーカイブ",
  archiveChallenge: "チャレンジをアーカイブ",
  archived: "アーカイブ",
  archivedChallenges: "アーカイブ",
  archiveUser: "アーカイブ",
  archivingChallenge: "チャレンジがアーカイブされた後は、他の参加者がアクセスしたりアイデアを投稿することは出来ません。",
  areYouSureToDeleteChallenge: "本当にチャレンジを削除しますか？",
  areYouSureToDeleteIdea: "本当にアイデアを削除しますか？",
  areYouSureYouWantToDeleteChangelog: "本当に変更ログを削除しますか？",
  areYouSureYouWantToDeleteXIdea: "本当に選択された{{count}}件のアイデアを削除しますか？",
  areYouSureYouWantToDeleteXIdea_plural: "本当に選択された{{count}}件のアイデアを削除しますか？",
  areYouSureYouWantToDeleteXMember: "本当に選択された{{count}}人のメンバーを削除しますか？",
  areYouSureYouWantToDeleteXMember_plural: "本当に選択された{{count}}人のメンバーを削除しますか？",
  askQuestion: "質問する",
  assign: "割り当てる",
  assignedXToName: "{{count}}件のアイデアを{{name}}にカテゴライズしました",
  assignedXToName_plural: "{{count}}件のアイデアを{{name}}にカテゴライズしました",
  assignToCategory: "カテゴリー割り当て",
  assignToNewCategory: "新しいカテゴリーに割り当てる",
  associatedXTagToSelectedIdeas: "選択されたアイデアに{{count}}件のタグを関連づけました",
  associatedXTagToSelectedIdeas_plural: "選択されたアイデアに{{count}}件のタグを関連づけました",
  attachments: "添付ファイル",
  attachmentsCanHelp: "画像やドキュメントを添付することが出来ます。",
  autoEvaluation: "自動評価",
  availableTeams: "グループ",
  avoidCommonPassword: "よく使われるパスワードを避ける",
  avoidLowContrast: "文字が見やすいように背景色とのコントラストを高くしましょう。",
  awards: "受賞数",
  back: "戻る",
  backgroundColor: "背景",
  beTheFirstToVote: "一番に投票しましょう",
  brand: "ブランド",
  cancel: "キャンセル",
  categories: "カテゴリー",
  category: "カテゴリー",
  categoryName: "カテゴリー名",
  categoryNameIsRequired: "カテゴリー名は必須です。",
  challenge: "チャレンジ",
  challengeDetails: "チャレンジ詳細",
  challengeParticipatedIn: "チャレンジ参加数",
  challengePrivateExplanation: "プライベートチャレンジは指定されたメンバーのみ参加できます。",
  challenges: "チャレンジ",
  challengeTeamExplanation: "チームチャレンジは全てのワークスペースメンバーが参加出来ます。",
  changelog: "変更ログ",
  changePassword: "パスワード設定",
  changeRole: "役割変更",
  chooseALevelOfVisibility: "チャレンジの可視レベルを設定することができます。参加者はメールで通知されます。",
  clear: "クリア",
  clearFilters: "フィルターをクリアする",
  clickToSeeMoreDetails: "クリックして詳細を見る",
  clickToSeeYourChallenges: "クリックしてチャレンジ一覧に戻る",
  close: "閉じる",
  code: "コード",
  collaborationStage: "コラボレーション",
  completedChallenges: "終了済み",
  completedOn: "終了日時",
  completedStage: "終了済み",
  confirm: "了解",
  confirmDeleteChallengeComment: "本当にこのコメントを削除しますか？関連する返信も全て削除されます。",
  confirmDeleteIdeaComment: "本当にこのコメントを削除しますか？",
  contrastIsTooLow: "前景色と背景色とのコントラスト比率を上げると文字がもっと読みやすくなります",
  contributions: "貢献",
  contributor: "投稿者",
  contributors: "投稿者",
  contributorsGroup: "投稿者グループ",
  createChallenge: "新しいチャレンジ",
  createWorkspace: "新しいワークスペース",
  custom: "カスタム",
  customDomain: "カスタムドメイン",
  customDomainAllowsYouTo: "独自のドメインを設定する事ができます。",
  customizeTheme: "カスタムテーマ",
  customMessage: "カスタムメッセージ",
  customSubdomain: "カスタムサブドメイン",
  customSubdomainWillBe: "サブドメインを設定する事ができます。",
  dashboard: "ダッシュボード",
  date: "日付",
  day: "日",
  days: "日",
  default: "デフォルト",
  defaultWorkspace: "デフォルト",
  delete: "削除",
  deleteAccount: "アカウントを削除",
  deleteCategory: "カテゴリーを削除する",
  deleteChallenge: "チャレンジを削除",
  deleteChangelog: "ログを削除",
  deleteComment: "コメントを削除",
  deleteIdea: "アイデアを削除",
  deleteMember: "削除する",
  deleteUser: "削除",
  deleteWorkspace: "ワークスペースを削除",
  deleteXCategory: "「{{name}}」カテゴリーを削除",
  deletingYourAccountWillBePermanent: "アカウントは永久的に削除されます",
  describeChallenge: "背景、制限、目的などを説明しましょう。",
  describeItInMoreDetail: "詳細",
  descriptionIsRequired: "詳細は必須項目です",
  design: "デザイン",
  discussFurther: "議論する",
  discussions: "ディスカッション",
  documents: "ドキュメント",
  draft: "ドラフト",
  drafts: "下書き",
  draftStage: "下書き",
  dropFileHere: "アップロードしたいファイルを、ここにドラッグ＆ドロップ",
  dropFilesHere: "アップロードしたいファイルを、ここにドラッグ＆ドロップ",
  edit: "編集",
  editCategory: "カテゴリーを編集",
  editChallenge: "チャレンジ編集",
  editIdea: "アイデア編集",
  editTiming: "時間を変更する",
  email: "メールアドレス",
  emailIsRequired: "メールアドレスは必須項目です",
  end: "終了",
  endsWhenYouSave: "保存後に終了",
  engagement: "エンゲージメント",
  english: "英語",
  enterChallengeDescription: "詳細",
  enterChallengeTitle: "タイトル",
  enterDescription: "詳細を入力してください",
  enterEmailHere: "メールアドレスを入力",
  enterMessage: "メッセージを入力してください",
  enterNameHere: "名前を入力",
  enterNewCategoryName: "新しいカテゴリー名を入力",
  enterNewCategoryNameHere: "新しいカテゴリー名を入力",
  enterNewWorkspaceHere: "新しいワークスペース名を入力",
  enterTitle: "タイトルを入力してください",
  error: "エラー",
  errorOccurred: "エラーが起きました",
  evaluationStage: "評価",
  existingTagsHaveBeenRemoved: "既存のタグが選択されたアイデアから削除されました",
  explainReward: "報酬の説明",
  extend: "延長する",
  favorites: "お気に入り",
  fieldIsRequired: "このフィールドは入力必須です。",
  fileExceedsSizeLimit: "アップロードされたファイルはサイズの上限を超えています",
  filterByContributions: "貢献順",
  filterByTags: "タグで探す",
  filterByTeam: "チームで探す",
  findAvailableMediaTypes: "メディアを探す",
  fix: "修正",
  forgotPassword: "パスワードを忘れた",
  fullLogo: "ロゴ",
  fullTeam: "全てのチーム",
  generalSettings: "一般設定",
  givePraise: "称賛する",
  googleImageSearch: "Google 画像検索",
  grantWorkspaceAdmin: "管理者権限を与える",
  groups: "グループ",
  guest: "ゲスト",
  help: "ヘルプ",
  hours: "時間",
  idea: "アイデア",
  ideas: "アイデア",
  ideasWillBeUnassigned: "関連するアイデアはカテゴリー割り当てから外されます。",
  ideationStage: "発想",
  ideaWasImportedFromAChallenge: "このアイデアはチャレンジから投稿されました",
  ideaWasSentDirectly: "このアイデアは直接投稿されました",
  ideaWasSentViaApp: "このアイデアはアプリから投稿されました",
  ideaWasSentViaEmail: "このアイデアはメールから投稿されました",
  ideaWasSentViaSlack: "このアイデアはSlackから投稿されました",
  images: "画像",
  improvement: "改善",
  inactive: "非アクティブ",
  insert: "挿入",
  invalidEmailAddress: "メールアドレスは無効です",
  invitationMessage: "招待メッセージ",
  inviteParticipantAsGuest: "ゲストメンバーとして招待しますか？",
  invitePeople: "招待する",
  inviteX: "{{name}}を招待する",
  japanese: "日本語",
  leaveWorkspace: "ワークスペースを去る",
  letsCreateFirstWorkspace: "最初のワークスペースを作成しましょう！",
  linkedIdeas: "アイデアリンク",
  linkIdea: "アイデアリンク",
  linkToIdeas: "アイデアリンク",
  linkToXIdea: "選択された{{count}}件のアイデアとリンクする",
  linkToXIdea_plural: "選択された{{count}}件のアイデアとリンクする",
  listView: "リスト",
  live: "ライブ",
  liveChallenges: "進行中",
  loading: "ローディング...",
  loadMore: "もっと読み込む",
  logo: "ロゴ",
  logout: "ログアウト",
  makeDefault: "デフォルト",
  makeSureValidTimes: "時間に誤りがないか確認してください。",
  maxUploadFileSize: "ファイルサイズ上限",
  medium: "中程度",
  member: "メンバー",
  members: "メンバー",
  menu: "メニュー",
  menuTextColor: "メニュー",
  minute: "分",
  minutes: "分",
  missingName: "名無し",
  moderators: "モデレーター",
  month: "ヶ月",
  months: "ヶ月",
  mostActivities: "最多活動中",
  mostActivity: "活動順",
  mostContributions: "最貢献",
  mostIdeasContributed: "アイデア貢献数",
  mostPraiseReceived: "称賛受賞",
  mostRecent: "最新順",
  mostVotes: "最多投票順",
  moveToNextStage: "次のステージへ進む",
  moveToPreviousStage: "前のステージへ戻る",
  multipleContributors: "複数の投稿者",
  mustBeLessThanXCharactersLong: "{{count}}字以内にしてください。",
  mustBeValidDomain: "正しいドメインを入力してください",
  name: "名前",
  nameIsRequired: "名前は必須項目です",
  navBar: "ナビゲーション",
  new: "新着",
  newChangelog: "新しい変更ログ",
  newPassword: "新しいパスワード",
  newPasswordIsRequired: "新しいパスワードは必須項目です",
  next: "次へ",
  noChallenges: "チャレンジ無し",
  noQualifyingTopContributions: "貢献の優秀賞はありません",
  noQualifyingTopIdeas: "アイデアの優秀賞はありません",
  noSearchResults: "検索結果0件",
  notApplicable: "適用外",
  notifications: "通知",
  off: "オフ",
  oldest: "古い順",
  oldPassword: "古いパスワード",
  on: "オン",
  openMenu: "メニューを開く",
  optional: "オプション",
  other: "その他",
  otherFilters: "他のフィルター",
  pageDoesNotExist: "リクエストされたページは存在しません",
  participants: "参加者",
  participantWillBeInvitedAsGuest: "この参加者はワークスペースメンバーではありませんが、ゲストとしてチャレンジに招待する事ができます。",
  password: "パスワード",
  passwordIsRequired: "パスワードは必須項目です",
  passwordStrength: "パスワードの強力さ",
  pendingInvitation: "Pending",
  peopleAreEngagingWithChallenge: "複数の人がエンゲージしています",
  peopleAreEngagingWithComment: "複数の人がエンゲージしています",
  pleaseEnterValidURL: "正しいアドレスを入力してください",
  pleaseEnterValidXURL: "{{name}}のアドレスを入力してください",
  pleaseEnterValidYouTube: "キーワードかYouTubeのアドレスを入力してください",
  pleaseTryAgainLater: "少し時間をおいてください。",
  pointsReceived: "受け取りポイント",
  post: "投稿",
  praise: "称賛",
  praises: "称賛",
  preferredLanguage: "使用する言語",
  preview: "プレビュー",
  privacy: "プライバシー",
  private: "プライベート",
  process: "プロセス",
  profile: "プロフィール",
  profilePhoto: "プロフィール画像",
  publishChallenge: "チャレンジを開始する",
  published: "公開",
  question: "質問",
  questions: "質問",
  received: "受け取り",
  receivesNotifications: "通知を受ける",
  recentlyCompleted: "最近完了したチャレンジ",
  recognition: "評価",
  reloadNow: "再読み込み",
  remove: "削除",
  removedXFromYourWorkspace: "{{name}}がワークスペースから削除されました",
  removedXIdeaFromY: "{{name}}から{{count}}件のアイデアを外しました",
  removedXIdeaFromY_plural: "{{name}}から{{count}}件のアイデアを外しました",
  removeXFromWorkspace: "本当に{{name}}をワークスペースから削除しますか？ 過去の投稿などはそのまま残ります。",
  renameCategory: "カテゴリー名を変更する",
  reOpen: "再オープン",
  reopeningWillEraseActivitiesFromCollaboration: "再オープンすると、コラボレーションステージの間に投稿された貢献は全て削除されます。",
  reopeningWillEraseActivitiesFromEvaluation: "再オープンすると、投票数がリセットされます。",
  reopeningWillEraseActivitiesFromCompleted: "再オープンすると、報酬がキャンセルされます。",
  reset: "リセット",
  resetPassword: "パスワードをリセットする",
  review: "確認",
  revokeWorkspaceAdmin: "管理者権限を取り消す",
  reward: "報酬",
  role: "役割",
  save: "保存",
  saveChanges: "保存",
  savePhoto: "画像を保存",
  saving: "保存中",
  savingChanges: "保存中...",
  schedule: "スケジュール",
  scheduledChallenges: "開始予定",
  score: "スコア",
  search: "検索",
  searchChallenges: "チャレンジを探す",
  searchContributions: "貢献を探す",
  searchForImages: "画像検索",
  searchIdeas: "アイデアを探す",
  searchMembers: "メンバー検索",
  second: "秒",
  seconds: "秒",
  seeAllChallenges: "全てのチャレンジ",
  seeDetails: "詳細を見る",
  select: "選択",
  selectAllIdeas: "全てのアイデアを選択",
  selectAllMembers: "全てのメンバーを選択",
  selectCategories: "カテゴリーを選択",
  selectIdea: "選択する",
  selectParticipants: "参加者",
  sendInvitation: "招待する",
  setDurationForPhrase: "発想、コラボ、評価。それぞれのステージ毎に時間を設定出来ます。",
  setOptions: "オプション",
  setPassword: "パスワードを設定",
  setRewardsPointsCertificates: "チャレンジ報酬を設定する事が出来ます。",
  setTimeline: "タイムライン",
  shareAnonymously: "匿名で投稿する",
  shared: "シェア数",
  shareIdea: "アイデアをシェア",
  shareRelatedIdea: "関連するアイデアをシェア",
  showLess: "少なく見る",
  showMore: "もっと見る",
  showPassword: "パスワードを表示",
  signIn: "ログイン",
  signUp: "サインアップ",
  slides: "スライド",
  someoneIsAskingQuestion: "誰かが質問をしています",
  someoneIsDiscussing: "誰かが議論をしています",
  someoneIsSharingIdea: "誰かがアイデアをシェアしています",
  someoneIsSharingRelatedIdea: "誰かが関連するアイデアをシェアしています",
  someoneIsWritingPraise: "誰かが称賛を書いています",
  somethingWentWrong: "原因不明のエラーが起きました。",
  sortBy: "ソート",
  squareLogo: "四角ロゴ",
  starIdea: "お気に入り",
  starred: "お気に入り",
  start: "開始",
  startsWhenCollaborateEnded: "コラボレーション終了後に開始します。",
  startsWhenIdeateEnded: "発想ステージ終了後に開始します。",
  startsWhenYouSave: "保存後に開始",
  status: "ステータス",
  strong: "強力",
  subdomainIsRequired: "サブドメインは必須項目です",
  submit: "送信",
  submittedByX: "Xにより投稿されました",
  successfullyRenamedToX: "{{name}}として名前を変更しました。",
  successfullyUpdatedX: "{{title}}が更新されました",
  superAdmin: "スーパー管理",
  tagSelectedIdeas: "選択したアイデアにタグづけする",
  team: "チーム",
  teamMember: "チームメンバー",
  teamMemberIdeasAreHidden: "他の参加者のアイデアは集団思考を避ける為、コラボレーションステージになるまで非表示のままになります。",
  thai: "タイ語",
  theme: "テーマ",
  thereAreNewActivities: "新規のアクティビティーを読み込む事が出来ます",
  theWorkspaceInfoIsUpdated: "ワークスペース情報がアップデートされました",
  thisMonth: "今月",
  thisStageHasAlreadyEnded: "このステージは既に終了してます",
  thisWeek: "今週",
  thisYear: "今年",
  timeline: "タイムライン",
  tip: "Tip",
  titleIsRequired: "タイトルは必須項目です",
  titleOrDescriptionIsRequired: "タイトル又は詳細は必須項目です",
  toggleFilters: "フィルターを切り替える",
  toMakeYourPasswordMoreSecure: "パスワードを強力にする為には",
  topContributions: "優秀賞（貢献）",
  topIdeas: "優秀賞（アイデア）",
  topParticipation: "トータル貢献度",
  total: "トータル",
  typeNameOrEmail: "名前・メールアドレス",
  unarchive: "アンアーカイブ",
  unArchiveChallenge: "チャレンジをアンアーカイブ",
  unArchivingChallenge: "アーカイブされたチャレンジを復元した後は、他の参加者がアクセス痴sたりアイデアを投稿する事が出来ます。",
  unassigned: "未割り当て",
  unassignedIdeas: "未割り当て",
  unauthorizedAccess: "アクセス権がない、または認証に失敗しました。",
  unauthorizedToEdit: "編集権限がありません",
  underEvaluation: "評価中",
  unknown: "不明",
  unselectIdea: "選択を解除する",
  unstarIdea: "お気に入りを解除する",
  upcomingStage: "開始予定",
  update: "更新",
  upload: "アップロード",
  uploadYourLogo: "カスタムロゴ",
  useBothFormatsOfYourLogo: "ロゴは通常と四角バージョンの両方をアップロードしましょう。四角バージョンは主にタブレットやスマホで見た時に反映されます。",
  useInventValueLogo: "Invent Valueのロゴ",
  useInventValueTheme: "Invent Valueのテーマ",
  useUncommonWords: "あまり使われない単語を使う",
  useUniquePhrase: "ユニークなフレーズを使う",
  useYourFullName: "実名と顔写真を使い、チームメイトから認識されやすくしましょう。",
  verifyingCredentials: "認証中",
  videos: "動画",
  viewAllIdeasWithThisTag: "このタグに関連するアイデアを見る",
  votes: "投票",
  votesLeft: "残り投票",
  warning: "警告",
  weak: "弱い",
  whatsTheBigIdea: "アイデアの概要",
  whatsYourChallengeAbout: "チャレンジの概要を簡潔に述べましょう。",
  willDeleteTheWorkspace: "ワークスペース、チャレンジ、アイデア、その他の関連する情報は全て削除されます。",
  workspaceAdmin: "ワークスペース管理",
  workspaceInfo: "ワークスペース情報",
  workspaceName: "ワークスペース名",
  workspaceNameIsRequired: "ワークスペース名は必須項目です",
  workspaces: "ワークスペース",
  workspaceTeam: "ワークスペースチーム",
  writeDescriptionHere: "詳細を入力",
  writeYourDiscussionHere: "議論を入力",
  writeYourIdeaHere: "アイデアを入力",
  writeYourPraiseHere: "称賛を入力",
  writeYourQuestionHere: "質問を入力",
  xContribution: "{{count}}貢献",
  xContribution_plural: "{{count}}貢献",
  xDay: "{{count}}日",
  xDay_plural: "{{count}}日",
  xHasBeenAdded: "{{title}}の追加に成功しました。",
  xHasBeenCreated: "{{title}}の作成に成功しました。",
  xHasBeenDeleted: "{{title}}の削除に成功しました。",
  xHasBeenUpdated: "{{title}}が更新されました",
  xHour: "{{count}}時間",
  xHour_plural: "{{count}}時間",
  xIdeaHasBeenLinkedToYOtherIdea: "{{title}}は他の{{count}}件のアイデアとリンクされました",
  xIdeaHasBeenLinkedToYOtherIdea_plural: "{{title}}は他の{{count}}件のアイデアとリンクされました",
  xIdeaHaveBeenDeleted: "{{count}つのアイデアが削除されました",
  xIdeaHaveBeenDeleted_plural: "{{count}つのアイデアが削除されました",
  xIdeaSelected: "{{count}つのアイデアを選択中",
  xIdeaSelected_plural: "{{count}つのアイデアを選択中",
  xMemberHaveBeenDeleted: "{{count}人のメンバーが削除されました",
  xMemberHaveBeenDeleted_plural: "{{count}人のメンバーが削除されました",
  xMemberSelected: "{{count}人選択中",
  xMemberSelected_plural: "{{count}人選択中",
  xMinute: "{{count}}分",
  xMinute_plural: "{{count}}分",
  xMonth: "{{count}}ヶ月",
  xMonth_plural: "{{count}}ヶ月",
  xSecond: "{{count}}秒",
  xSecond_plural: "{{count}}秒",
  xUsersHasBeenInvitedToJoinWorkspace: "{{count}}人をワークスペースに招待しました",
  xUsersHasBeenInvitedToJoinWorkspace_plural: "{{count}}人をワークスペースに招待しました",
  xWeek: "{{count}}週間",
  xWeek_plural: "{{count}}週間",
  xYear: "{{count}}年",
  xYear_plural: "{{count}}年",
  year: "年",
  years: "年",
  youAreNotAuthorizedToDeleteSelectedIdeas: "以下のアイデアを削除する権限はありません",
  youDoNotHaveAccessToThisWorkspace: "アクセス権がない、または認証に失敗しました。",
  youDoNotHavePermissionToViewThisPage: "アクセス権がありません。",
  yourAccountHasBeenDeleted: "アカウントは削除されました。",
  yourChangelogHasBeenDeleted: "変更ログが削除されました",
  yourPasswordIsSet: "パスワードが設定されました",
  yourPasswordIsUpdated: "パスワードが変更されました。",
  yourProfileIsUpdated: "プロフィールが更新されました",
  yourWorkspaceNameWillAppear: "ワークスペース名はアプリ画面の右上と、ブラウザーのタブに表示されます。",
  youWillLoseAccess: "ワークスペースや投稿した情報にアクセスが出来なくなります。",
  zeroActivities: "無活動",
  zeroContributions: "貢献無し",
  zeroVotes: "無投票",
  zeroNotifications: "新しい通知はありません",
  sortByNameAlphabetical: "A-Z",
  quickAdd: "クイック挿入",
  viewAllChangeLog: "全ての変更ログを見る",
  thereAreNoNewNotifications: "新しい通知はありません",
  pleaseTypeDeleteToConfirm: "{{keyword}}とタイプして削除を確認してください",
  workspaceHasBeenDeleted: "ワークスペースは削除されました",
  suggestions: "提案箱",
  reviewed: "審査済み",
  planned: "計画済み",
  implemented: "実装済み",
  changeStatus: "ステータス変更",
  filterBy: "フィルター",
  xUpdateStatusOfIdea: "はこのアイデアのステータスを【 {{status}} 】に変更しました。",
  selectABoard: "ボード選択",
  addNewBoard: "新規ボード作成",
  manageBoard: "ボード管理",
  enterNewBoardNameHere: "新しいボード名を入力してください",
  boardPrivateExplanation: "指定されたメンバーのみ参加できます。",
  xHasBeenEdited: "{{title}}の更新に成功しました。",
  manageParticipants: "参加者を管理する。",
  deleteBoard: "ボードを削除する",
  areYouSureToDeleteX: "本当に{{name}}を削除しますか？",
  createBoard: "新規ボード作成",
  ideaBoard: "アイデアボード",
  createYourFirstBoard: "プライベートボードを作成しましょう",
  createYourFirstBoardExplanation: "プライベートボードにワークスペースメンバーを招待してアイデアをシェアしましょう。ボードやアイデアには数の制限がありません。",
  createPrivateBoard: "ボードを作成する",
  organizeIdeasEasily: "アイデアを整理しましょう",
  organizeIdeasEasilyExplanation: "アイデアをカテゴリーに振り当てる事で整理する事ができます。",
  shareYourIdeaNow: "アイデアをシェアしましょう!",
  shareYourIdeaNowExplanation: "アイデアをシェアするのはとても簡単です。画像や動画なども添付してみましょう。",
  newIdeaBoard: "New ボード",
  newChallenge: "New チャレンジ",
  newSuggestion: "New 提案",
  newWorkspaceMember: "New メンバー",
  share: "シェア",
  getLink: "リンクをシェア",
  linkCopied: "リンクをコピーしました",
  done: "完了",
  onlyBoardMembersCanViewThisIdea: "このアイデアはボードメンバーのみが回覧できます",
  settings: "設定",
  allowIdeaBoardManagement: "メンバーによるボード管理",
  allowIdeaBoardManagementExplanation: "管理者以外のメンバーによるボードの作成、管理、削除する権限を与えます。",
  sendReminder: "リマインダーを送信",
  sendReminderExplanation: "チャレンジにまだ参加していないメンバーにリマインダーを送信しますか？",
  reminderHasBeenSent: "リマインダーを送信しました。",
  votesPerParticipant: "投票数 / メンバー",
  failedToVerifyMention: "メンションの確認に失敗しました。再度お試しください。",
  inviteParticipant: "招待しますか？",
  xNeedToBeInvitedToBoardForMention: "{{name}}をボードに招待しますか？プライベートボードは参加者のみがアクセスできます。",
  xSuggestionsAssignedToStatus: "{{count}}件のアイデアが{{status}}に割り当てられました。",
  xSuggestionsAssignedToStatus_plural: "{{count}}件のアイデアが{{status}}に割り当てられました。",
  innovationDirectory: "ディレクトリ",
  assignToStatus: "ステータスを割り当てる",
  createThisChallenge: "このチャレンジを作成する",
  viewChallenge: "チャレンジを見る",
  privateChallenge: "プライベートチャレンジ",
  more: "その他",
  noResultsFound: "結果が見つかりませんでした。",
  onlyChallengeMemberCanViewThis: "チャレンジメンバーのみが回覧できます",
  getIdeas: "アイデアを探す",
  duration: "期間",
  challengeHasStarted: "チャレンジは開始済みです。",
  startsNow: "今すぐ",
  setEndTime: "終了日時をセット",
  startsWhenIdeationEndsOnX: "発想ステージ終了後: {{time}}",
  ideationStarts: "発想ステージ開始",
  ends: "終了",
  categoriesContributorsConnections: "カテゴリー、投稿者、コネクション",
  assignCategory: "カテゴリー割り当て",
  noAttachment: "添付ファイル無し",
  xAttachment: "{{count}}件の添付ファイル",
  xAttachment_plural: "{{count}}件の添付ファイル",
  recommendationEngine: "推奨エンジン",
  articles: "記事",
  visuals: "画像",
  addAttachments: "添付ファイル",
  download: "ダウンロード",
  resources: "リソース",
  addResource: "新規リソース",
  newResource: "新規リソース",
  principle: "指導原理",
  whitepaper: "ホワイトペーパー",
  article: "記事",
  link: "リンク",
  video: "動画",
  areYouSureYouWantToDeleteResource: "本当にリソースを削除しますか？",
  deleteResource: "リソースを削除",
  premium: "プレミア",
  public: "無料公開",
  enterBriefDescription: "概要を入力してください。",
  title: "タイトル",
  briefDescription: "概要",
  content: "コンテンツ",
  enterVideoUrl: "動画のURLを入力してください。",
  enterUrl: "URLを入力してください。",
  attachment: "添付",
  coverImage: "カバー画像",
  firstName: "名",
  lastName: "姓",
  company: "会社名",
  downloading: "ダウンロード中",
  copyLink: "リンクをコピー",
  viewAllResources: "全てのリソースを見る",
  xIsRequired: "{{name}}は必須項目です",
  description: "説明",
  requestedPageNotFound: "お探しのページは見つかりませんでした。",
  evaluation: "評価",
  autoEvaluate: "自動評価",
  issueCertificate: "証明書を発行",
  rewardInfo: "報酬を提供するとエンゲージメントを促進させる効果が見込めます。",
  issueCertificateInfo: "受賞者には、ダウンロードして保持できる証明書が発行されます。",
  autoEvaluateInfo: "共有および受け取った貢献に従ってチャレンジ勝者を選択します。",
  votesReceived: "投票(受)",
  praiseReceived: "称賛(受)",
  commentsReceived: "コメント(受)",
  ideasShared: "アイデア(送)",
  points: "ポイント",
  finishEvaluation: "評価を送信",
  totalPointsMustBe100: "合計ポイントが１００になるように振り分けてください。",
  pleaseConfirm: "ご確認ください",
  autoEvaluateWarning: "チャレンジの勝者を一人だけ、投票数やアクティビティーに応じて、自動的に選出します。",
  xResultsFound: "{{count}}件見つかりました",
  seeMore: "もっと見る",
  allFieldsAreRequired: "全てのフィールドは入寮必須項目です。",
  deleteX: "{{name}}を削除",
  confirmDeleteX: "本当にこの{{name}}を削除しますか？",
  writeXHere: "{{name}}を入力",
  askChallengeQuestions: "質問",
  askChallengeQuestionsHint: "チャレンジに質問があればここでシェアしましょう。",
  message: "メッセージ",
  viewXEarlierY: "{{count}}件の{{name}}を表示する",
  viewFewerX: "{{name}}を少なく表示する",
  addManyAtOnce: "まとめて追加する",
  enterCommaSeparatedListOfEmails: "コンマ区切りで複数のメールアドレスを指定してください。",
  messages: "メッセージ",
  linkedVotes: "リンク投票",
  voteFailed: "投票に失敗しました",
  stackedView: "スタック",
  allActivities: "全ての活動",
  pleaseEnterValidGiphy: "キーワードかGiphyのアドレスを入力してください",
  solutionGrouping: "ソリューショングループ",
  moderatorOnly: "モデレーターのみ",
  groupAndModerator: "グループとモデレーター",
  synthesisStage: "統合ステージ",
  synthesis: "統合",
  synthesisModerationEnds: "統合まとめ終了",
  addSolutions: "新規ソリューション",
  addNew: "新規New",
  groupedIdeas: "グループ済みのアイデア",
  addIdeasToSolution: "アイデアをソリューションに追加",
  whatsTheTitleOfYourSolution: "ソリューションのタイトルはなんですか？",
  addToSolution: "ソリューションに追加",
  solution: "ソリューション",
  deleteSolution: "ソリューションを削除",
  solutions: "ソリューション",
  confirmDeleteChallengeReply: "本当にこのコメントを削除しますか？",
  masonryView: "メーソンリー表示",
  solutionRequirements: "ソリューション必須条件",
  describeSolutionRequirements: "ソリューションとして成立する必須条件を説明しましょう（オプション）",
  enterSolutionRequirements: "ソリューションの必須条件を入力してください",
  showRequirements: "必須条件を表示",
  challengeDescription: "チャレンジ詳細",
  convertIdeaToSolution: "アイデアをソリューションにする",
  selectToAddToSolutions: "追加先のソリューションを選択:",
  yourChallengeModeratorIsSynthesizing: "チャレンジモデレーターがソリューションを統合中です。終了次第、メールでお知らせします。",
  pleaseAddSolutionsToMoveToNextPhase: "次のステージへ移る前に、ソリューションを追加してください。",
  pleaseConfirmSolutions: "ソリューションをご確認ください",
  submitNumSolutions: "{{count}}件のソリューションを確認",
  submitNumSolutions_plural: "{{count}}件のソリューションを確認",
  youHaveSubmittedNumSolutions: "{{count}}件のソリューションを送信しました",
  youHaveSubmittedNumSolutions_plural: "{{count}}件のソリューションを送信しました",
  solutionsWillBeAutomaticallyReleased: "送信されたソリューションは統合ステージの終了後、評価ステージに移行されます。または、いますぐ評価ステージに移行することが可能です。 ",
  editChoices: "選択を編集する",
  startEvaluationStage: "評価ステージを開始",
  numRelatedIdeas: "{{count}}件の関連アイデア",
  numRelatedIdeas_plural: "{{count}}件の関連アイデア",
  showAll: "全てを表示",
  searchSolutions: "ソリューション検索",
  view: "表示する",
  addToX: "{{name}}に追加",
  integration: "統合",
  xIntegration: "{{name}}インテグレーション",
  backToHome: "ホームに戻る",
  openX: "{{name}}を開く",
  exportChallenge: "チャレンジをエクスポート",
  ideaBoards: "アイデアボード",
  export: "エクスポート",
  selectAFileType: "ファイルタイプを選択",
  thisChallengeWillBeExportedAsCSV: "このチャレンジは既存のテンプレートでCSVファイルとしてエクスポートされます",
  options: "オプション",
  convertTagsToCategories: "タグをカテゴリーにするConvert tags to categories",
  carryOverComments: "コメントを引き継ぐ",
  carryOverVotes: "投票を引き継ぐ",
  carryOverParticipants: "参加者を引き継ぐ",
  backToX: "{{name}}に戻る",
  reminder: "リマインダー",
  sendIndividualReminder: "個別のリマインダーを送信",
  customizeTheReminderMessage: "そのままメッセージを送信するか、カスタマイズして送信できます：",
  sendReminderToX: "{{name}}にリマインダーを送信",
  everyone: "全員",
  send: "送信",
  remindEveryone: "全員にリマインダーを送信",
  thisChallengeWillBeExportedAsGoogleSheets: "このチャレンジは既存のテンプレートでGoogle Sheetsにエクスポートされます",
  thisChallengeWillBeExportedAsGoogleSlides: "このチャレンジは既存のテンプレートでGoogle Slides",
  collapse: "閉じる",
  noQualifyingTopX: "該当するトップ{{name}}はありません",
  newBoard: "新規ボード",
  topSolutions: "トップソリューション",
  addX: "新規{{name}}",
  moveToWorkspace: "ワークスペースに移行する",
  sendRequestToMoveChallenge: "別のワークスペースに移行するリクエストを送信",
  sendMoveRequestExplanation: "リクエストを完了するまでに、最短でも７２時間の時間を要する事があります。あらかじめご了承グラさい。",
  selectX: "{{name}}を選択",
  workspace: "ワークスペース",
  sendRequestViaEmail: "メールでリクエストを送信",
  ungroupedIdeas: "グループ外のアイデア",
  allIdeasNotInSolution: "ソリューションにまだ追加されていない、全てのアイデアを表示",
  unknownErrorOccurred: "原因不明のエラーが起きました",
  contactSupport: "サポートと連絡する",
  continueEditing: "編集を継続する",
  finalizeChallenge: "チャレンジを終了する",
  allowSuggestionFeatures: "提案箱",
  allowSuggestionFeaturesExplanation: "提案箱を利用可能にして、メニューに表示します。",
  ideasContributedBy: "アイデア投稿者:",
  xIdeas: "{{count}}件のアイデア",
  xIdeas_plural: "{{count}}件のアイデア",
  xSolutions: "{{count}}件のソリューション",
  xSolutions_plural: "{{count}}件のソリューション",
};
